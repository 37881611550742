import { ThemeProvider } from "next-themes";
import "../css/tailwind.css";
import { GlobalProvider } from '../context/GlobalContext';
import Head from "next/head";

function MyApp({ Component, pageProps }) {
  return (
    <GlobalProvider>
      <Head>
        <title>ReBillion - AI Tools & Transaction Coordination for Real Estate</title>
	<meta name="description" content="ReBillion.ai manages your real estate transactions from start to finish with the support of an AI copilot, ensuring punctuality, strict adherence to compliance." />
	<meta property="og:type" content="website" />
	<meta property="og:locale" content="en_US" />
	<meta property="og:site_name" content="ReBillion" />
	<meta property="og:title" content="ReBillion" />
	<meta property="og:description" content="ReBillion.ai manages your real estate transactions from start to finish with the support of an AI copilot, ensuring punctuality, strict adherence to compliance, and complete peace of mind." />
	<meta property="og:url" content="https://rebillion.ai/" />
	<meta property="og:image" content="https://tc.rebillion.ai/wp-content/uploads/2024/05/zipwp-image-16094062.jpeg" />
	<meta name="twitter:card" content="summary_large_image" />
	<meta name="twitter:title" content="ReBillion" />
	<meta name="twitter:description" content="ReBillion.ai manages your real estate transactions from start to finish with the support of an AI copilot, ensuring punctuality, strict adherence to compliance, and complete peace of mind." />
	<meta name="twitter:image" content="https://tc.rebillion.ai/wp-content/uploads/2024/05/zipwp-image-16094062.jpeg" />
        <link rel="icon" href="/favicon.ico" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
         <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
          rel="stylesheet"
        />
      </Head>
      <ThemeProvider attribute="class">
        <Component {...pageProps} />
      </ThemeProvider>
    </GlobalProvider>
  );
}

export default MyApp;
