import React, { createContext, useState, useContext } from 'react';

// Create the context
const GlobalContext = createContext();

// Provider component that wraps your application
export const GlobalProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    
    // Provide all state variables and setters through context
    const value = {
        user,
        setUser,
    };

    return (
        <GlobalContext.Provider value={value}>
            {children}
        </GlobalContext.Provider>
    );
};

// Custom hook to use the global context
export const useGlobalContext = () => useContext(GlobalContext);
